<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'employees' }">Employees</router-link>
        </li>
        <li class="breadcrumb-item active">Edit/{{ this.$route.params.id }}</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">New Employee</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'employees' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <div class="card">
              <div class="card-header">
                <h2>Personal Information</h2>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="firstName" class="form-label"
                        >First Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="firstName"
                        v-model="$v.form.firstName.$model"
                        :validator="$v.form.firstName"
                      />
                      <span
                    class="error "
                    v-if="$v.form.firstName.$error == true"
                    >First Name is required</span
                  >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="middleName" class="form-label"
                        >Middle Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="middleName"
                        v-model="$v.form.mi.$model"
                        :validator="$v.form.mi"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="lastName" class="form-label">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    v-model="$v.form.lastName.$model"
                    :validator="$v.form.lastName"
                  />
                  <span
                    class="error "
                    v-if="$v.form.lastName.$error == true"
                    >Last Name is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Gender</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.gender.$model"
                    :validator="$v.form.gender"
                  >
                    <option selected>Select Gender</option>
                    <option value="NOT SPECIFIED">Not Specified</option>
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                  </select>
                  <span
                        class="error "
                        v-if="$v.form.gender.$error == true"
                        >Please select a gender</span
                      >
                </div>
                <div class="mb-3">
                  <label class="form-label">Civil Status</label>
                  <select
                    class="form-select"
                    v-model="$v.form.civilstatus.$model" :validator="$v.form.civilstatus">
                    <option selected>Select CivilStatus</option>
                    <option value="UNMARRIED">UNMARRIED</option>
                    <option value="MARRIED">MARRIED</option>
                    <option value="WIDOWED">WIDOWED</option>
                    <option value="LEGALLY SEPARATED">LEGALLY SEPARATED</option>
                  </select>
                  <span
                        class="error "
                        v-if="$v.form.civilstatus.$error == true"
                        >Civil Status is required</span
                      >
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="firstName" class="form-label"
                        >Email Address</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="firstName"
                        v-model="$v.form.emailaddress.$model"
                        :validator="$v.form.emailaddress"
                      />
                      <span
                        class="error "
                        v-if="$v.form.emailaddress.$error == true"
                        >Email address is required</span
                      >
                      <span v-if="$v.form.emailaddress.email && !$v.form.emailaddress.email">Enter valid email address</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="middleName" class="form-label"
                        >Mobile number</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="middleName"
                        v-model="$v.form.mobileno.$model"
                        :validator="$v.form.mobileno"
                      />
                      <span v-if="$v.form.mobileno.$error == true">
                      <span
                        class="error "
                        v-if="$v.form.mobileno.required == true"
                        >Mobile number is required</span
                      >
                      <span
                        class="error "
                        v-else-if="$v.form.mobileno.maxLength == true"
                        >Mobile number must be 10 digits</span
                      >
                      <span
                        class="error "
                        v-else-if="$v.form.mobileno.numeric == true"
                        >Mobile number must be numeric only</span
                      >
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="firstName" class="form-label">Age</label>
                      <input
                        type="number"
                        class="form-control"
                        id="firstName"
                        readonly
                        v-model="$v.form.age.$model"
                        :validator="$v.form.age"
                      />
                      <span
                        class="error "
                        v-if="$v.form.age.$error == true"
                        >Age is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="middleName" class="form-label"
                        >Date of Birth</label
                      >
                      <datepicker typeable="true"  placeholder="Select Date" @input="calculateAge" format="yyyy-MM-dd" class="form-control" v-model="$v.form.birthday.$model" :validator="$v.form.birthday"></datepicker>
                      <span
                        class="error "
                        v-if="$v.form.birthday.$error == true"
                        >Date of birth is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">PAN Number</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    v-model="$v.form.pan.$model"
                    :validator="$v.form.pan"
                  />
                  <span
                    class="error "
                    v-if="$v.form.pan.$error == true"
                    >Pan number is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Aadhar Number</label>
                  <input
                    type="number"
                    class="form-control"
                    id="lastName"
                    v-model="$v.form.aadhar.$model"
                    :validator="$v.form.aadhar"
                  />
                  <span
                    class="error "
                    v-if="$v.form.aadhar.$error == true"
                    >Aadhar Number is required</span
                  >
                </div>
                <div class="mb-3">
                  <label for="homeaddress" class="form-label"
                    >Home Address</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="homeaddress"
                    v-model="$v.form.homeaddress.$model"
                    :validator="$v.form.homeaddress"
                  />
                  <span
                    class="error "
                    v-if="$v.form.homeaddress.$error == true"
                    >Home Address is required</span
                  >
                </div>
                <div class="mb-3">
                  <label for="image" class="form-label"
                    >Upload Profile photo</label
                  >
                  <input type="file"  ref="file"  @change="onChangeFileUpload"   class="form-control" id="image" />
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <div class="card">
              <div class="card-header">
                <h2>Employee Details</h2>
              </div>
              <div class="card-body">
                <div class="mb-3">
                    <label class="form-label"
                        >Company</label
                      >
                  <b-form-select
                    class="form-select"
                    v-model="$v.form.company.$model"
                    :validator="$v.form.company"
                    :options="companies"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.company.$error == true"
                    >Company is required</span
                  >
                </div>
                <div class="mb-3">
                    <label class="form-label"
                        >Department</label
                      >
                  <b-form-select
                    class="form-select"
                    v-model="$v.form.department.$model"
                    :validator="$v.form.department"
                    :options="departments"
                    @change="getJobTitle"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.department.$error == true"
                    >Department is required</span
                  >
                </div>
                <div class="mb-3">
                    <label class="form-label"
                        >Job Title / Positions</label
                      >
                  <b-form-select
                    class="form-select"
                    v-model="$v.form.jobposition.$model"
                    :validator="$v.form.jobposition"
                    :options="jobpositions"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.jobposition.$error == true"
                    >Job position/Title is required</span
                  >
                </div>
                <div class="mb-3">
                  <label for="homeaddress" class="form-label"
                    >Employee ID</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="homeaddress"
                    v-model="$v.form.emp_id.$model"
                    :validator="$v.form.emp_id"
                  />
                  <span
                    class="error "
                    v-if="$v.form.emp_id.$error == true"
                    >Employee ID is required</span
                  >
                </div>
                <div class="mb-3">
                  <label for="homeaddress" class="form-label"
                    >Email Address (Company)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="homeaddress"
                    v-model="$v.form.companyemail.$model"
                    :validator="$v.form.companyemail"
                  />
                  <span
                    class="error "
                    v-if="$v.form.companyemail.$error == true"
                    >Company Email address is required</span
                  >
                </div>
                <div class="mb-3">
                    <label class="form-label"
                        >Leave Group</label
                      >
                  <b-form-select
                    class="form-select"
                    v-model="$v.form.leaveprivilege.$model"
                    :validator="$v.form.leaveprivilege"
                    :options="leaveprivilege"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.leaveprivilege.$error == true"
                    >Leave group is required</span
                  >
                </div>
                <h4>Employment Information</h4>
                <div class="mb-3">
                    <label class="form-label"
                        >Employment Type</label
                      >
                  <b-form-select
                    class="form-select"
                    v-model="$v.form.employmenttype.$model"
                    :validator="$v.form.employmenttype"
                    :options="employmenttype"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.employmenttype.$error == true"
                    >Employment type is required</span
                  >
                </div>
                <div class="mb-3">
                    <label class="form-label"
                        >Employment Status</label
                      >
                  <b-form-select
                    class="form-select"
                    v-model="$v.form.employmentstatus.$model"
                    :validator="$v.form.employmentstatus"
                    :options="employmentstatus"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.employmentstatus.$error == true"
                    >Employment status is required</span
                  >
                </div>
                <div class="mb-3">
                  <label for="homeaddress" class="form-label"
                    >Official Start Date</label
                  >
                   <datepicker typeable="true" placeholder="Select Date" format="yyyy-MM-dd" class="form-control" v-model="$v.form.startdate.$model" :validator="$v.form.startdate"></datepicker>
                  <span
                    class="error "
                    v-if="$v.form.startdate.$error == true"
                    >Official start date is required</span
                  >
                </div>
                <div class="mb-3">
                  <label for="homeaddress" class="form-label"
                    >Date Regularized</label
                  >
                  <datepicker typeable="true" placeholder="Select Date" format="yyyy-MM-dd" class="form-control" v-model="$v.form.dateregularized.$model" :validator="$v.form.dateregularized"></datepicker>
                  <span
                    class="error "
                    v-if="$v.form.dateregularized.$error == true"
                    >Date Regularized is required</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button type="submit" class="btn btn-outline-primary space">Save</button>
            <router-link :to="{name: 'employees'}" class="btn btn-outline-primary">Cancel</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, email, maxLength, numeric } from "vuelidate/lib/validators";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import axios from 'axios';
require('../axios');
export default {
  components: {Datepicker},
  data() {
    return {
      form: {
        firstName: null,
        mi: null,
        image: null,
        lastName: null,
        gender: 'NOT SPECIFIED',
        civilstatus: null,
        emailaddress: null,
        mobileno: null,
        age: null,
        birthday: null,
        pan: null,
        aadhar: null,
        homeaddress: null,
        company: null,
        department: null,
        jobposition: null,
        emp_id: null,
        companyemail: null,
        leaveprivilege: null,
        employmenttype: null,
        employmentstatus: null,
        startdate: null,
        dateregularized: null,
      },
      file: '',
      jobpositions: []
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      mi: {
        
      },
      lastName: {
        required,
      },
      gender: {
      },
      civilstatus: {
        
      },
      emailaddress: {
        required,
        email
      },
      mobileno: {
        required,
        numeric,
        maxLength: maxLength(10)
      },
      age: {
        
      },
      birthday: {
        required,
      },
      pan: {
        required,
      },
      aadhar: {
        required,
      },
      homeaddress: {
        required,
      },
      company: {
        required,
      },
      department: {
        required,
      },
      jobposition: {
        required,
      },
      emp_id: {
        required,
      },
      companyemail: {
        required,
      },
      leaveprivilege: {
        required,
      },
      employmenttype: {
        required,
      },
      employmentstatus: {
        required,
      },
      startdate: {
        required,
      },
      dateregularized: {
        required,
      },
    },
  },
  mounted() {
      this.$store.dispatch("switchLoader",true);
      this.$store.dispatch("loadNewEmployeeOptions");
      },
  computed: {
    formOptions() {
      return this.$store.state.newEmployeeOptions;
    },
    companies() {
      return this.$store.state.newEmployeeOptions.company.map((item) => {
        return {
          value: item.company,
          text: item.company,
        };
      });
    },
    departments(){
       return this.$store.state.newEmployeeOptions.department.map((item) => {
        return {
          value: item.department,
          text: item.department,
        };
      });
    },
    employmenttype(){
      return [
        {
          value: 'Regular',
          text: 'Regular',
        },{
          value: 'Trainee',
          text: 'Trainee',
        }
      ]
    },
    leaveprivilege(){
      return this.$store.state.newEmployeeOptions.leavegroup.map((item) => {
        return {
          value: item.id,
          text: item.leavegroup,
        };
      });
    },
    employmentstatus(){
      return [
        {
          value: 'Active',
          text: 'Active',
        },{
          value: 'Archived',
          text: 'Archived',
        }
      ]
    },
  },
  methods:{
    calculateAge(){
      console.log(this.form.birthday);
      var today = new Date();
      var birthDate = new Date(this.form.birthday);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age--;
      }
      this.form.age =  age;
    },
    getJobTitle(){
      var dept_code = this.$store.state.newEmployeeOptions.department.filter((dpt) => dpt.department == this.form.department)[0];
      var tempPostions = this.$store.state.newEmployeeOptions.jobtitle.filter((jobTitle) => { return jobTitle.dept_code == dept_code.id
      });
      this.jobpositions = tempPostions.map((item) => {
          return {
            value: item.jobtitle,
            text: item.jobtitle,
          };
        });
      
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
      
    },
    onSubmit(){
       this.$v.$touch();
       console.log(this.$v.form);
       if (this.$v.$invalid){ 
         return;
        } 
      let formData = new FormData();
      formData.append('firstName', this.form.firstName);  
      formData.append('lastName', this.form.lastName);  
      formData.append('mi', this.form.mi);  
      formData.append('gender', this.form.gender);  
      formData.append('civilstatus', this.form.civilstatus);  
      formData.append('emailaddress', this.form.emailaddress);  
      formData.append('mobileno', this.form.mobileno);  
      formData.append('age', this.form.age);  
      formData.append('birthday', this.customFormatter(this.form.birthday));  
      formData.append('pan', this.form.pan);  
      formData.append('aadhar', this.form.aadhar);  
      formData.append('homeaddress', this.form.homeaddress);  
      formData.append('company', this.form.company);  
      formData.append('department', this.form.department);  
      formData.append('jobposition', this.form.jobposition);  
      formData.append('idno', this.form.emp_id);  
      formData.append('companyemail', this.form.companyemail);  
      formData.append('leaveprivilege', this.form.leaveprivilege);  
      formData.append('employmenttype', this.form.employmenttype);  
      formData.append('employmentstatus', this.form.employmentstatus);  
      formData.append('startdate', this.customFormatter(this.form.startdate));  
      formData.append('dateregularized', this.customFormatter(this.form.dateregularized));  
      formData.append('image', this.form.image);  

      axios.post('employee/add',
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }
          ).catch(({response}) => {
            console.log(response);
          this.flashMessage.error({
              message: 'Something went wrong!',
              time: 3000
            });
      }).then(({data}) => {
        console.log(data);
          if(data.success){
            this.flashMessage.success({
              message: data.success,
              time: 3000
            });
          }
          this.$router.push({name:'employees'});
      });
    },
    onChangeFileUpload(){
      this.form.image = this.$refs.file.files[0];
    },
  }
};
</script>